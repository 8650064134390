import React from 'react';
import { Provider } from 'redux-bundler-react';
import Layout from './Layout.tsx';
import axios from 'axios';
import { referalApiWS } from '../config/unsplash';
import { redirectToOktaLogin } from '../services/authService';
const root = (store) => {
  axios.interceptors.request.use((req) => {
    let { url } = req;
    if (url && url.includes(referalApiWS)) {
      let { accessToken } = store.getState().user;
      if (url.includes('/strapi-files')) {
        accessToken = process.env.API_CMS_TOKEN;
      }
      req.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return req;
  });
  axios.interceptors.response.use(
    ({ data }) => data,
    ({ response }) => {
      const { status, data } = response;
      status === 401 && redirectToOktaLogin();
      return Promise.reject(data);
    },
  );
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};
export default root;
