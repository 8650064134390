import { Suspense, useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import navHelper from 'internal-nav-helper';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import AgreementsPopin from '../components/AgreementsPopin/AgreementsPopin';
import MandatoryBUPopin from '../components/MandatoryBUPopin/MandatoryBUPopin';
import Konami from 'react-konami-code';
import { defaultLocale, dynamicActivate } from '../i18n';
import messagesEn from '../locales/en/messages';
import NavBar from '../components/atomic/atoms/nav-bar/nav-bar';
import { Footer } from '../components/atomic/atoms/footer/footer';

i18n.load({
  en: messagesEn,
});
i18n.activate('en');

interface PropTypes {
  userId?: string;
  doUpdateUrl: () => void;
  route: React.ComponentType;
  userLang: string;
  pathname: string;
  isLoggedIn: boolean;
  isUserAdmin: boolean;
}

const DefaultI18n = ({ isTranslated, children }) => (
  <span style={{ color: isTranslated ? undefined : 'red' }}>{children}</span>
);

const Layout = ({
  userId,
  doUpdateUrl,
  route,
  userLang,
  pathname,
  isLoggedIn,
  isUserAdmin,
}: PropTypes) => {
  useEffect(() => {
    dynamicActivate(defaultLocale);
  }, []);
  const Page = route;

  if (pathname.startsWith('/strapi-files/')) {
    return <Suspense fallback={null}><Page /></Suspense>
  }

  return (
    <I18nProvider i18n={i18n} defaultComponent={DefaultI18n}>
      <main
        onClick={navHelper(doUpdateUrl)}
        className="flex flex-col min-h-full relative"
        style={{
          backgroundColor: `var(--nj-semantic-color-background-neutral-secondary-default)`,
        }}
      >
        <NavBar isAdmin={isUserAdmin}></NavBar>
        <div className="flex-1 flex flex-col">
          <Suspense fallback={null}>{isLoggedIn ? <Page /> : null}</Suspense>
        </div>
        <Footer />
        {userId && <MandatoryBUPopin userId={userId} />}
        <AgreementsPopin />
      </main>
      <Konami />
    </I18nProvider>
  );
};

export default connect(
  'doUpdateUrl',
  'selectUserId',
  'selectPathname',
  'selectIsLoggedIn',
  'selectRoute',
  'selectUserLang',
  'selectIsUserAdmin',
  Layout,
);
