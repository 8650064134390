import { NJAvatar, NJIcon } from "@engie-group/fluid-design-system-react"
import { ReactNode, useEffect, useState } from "react"

const firstItem = "navitem0"

export type NavBarLinks = {
    href: string,
    id: string,
    name: ReactNode,
}

const getFirstLetters = (completeName: string): string => {
    if (completeName?.length < 1) return ""
    const words = completeName.split(' ');
    return words.map((w) => w[0].toUpperCase()).join('')
};

const getNavbarIconItems = (userId: string, doLogout): NavBarLinks[] => [{
    href: "",
    id: "divider",
    name: "",
},
{
    href: "/search_nav",
    id: "search",
    name: <NJIcon {...{ name: 'search', variant: 'brand' }} />
},
{
    href: `/profile/${userId}`,
    id: "profile",
    name: <NJIcon name="person" variant="brand" />
},
{
    href: `#`,
    id: "logout",
    name: <div onClick={() => doLogout}><NJIcon name="logout" variant="brand" /></div>
},
]

export const getMainNavbarItems = (isAdmin: boolean): NavBarLinks[] => {
    let navbarItems: NavBarLinks[] = [{
        href: "/projects",
        id: "navitem1",
        name: "Use Cases",
    },
    {
        href: "/inspirations",
        id: "navitem2",
        name: "Inspirations",
    },
    {
        href: "/data4u",
        id: "nav-data4u-item",
        name: "Data4U",
    },
    {
        href: "/events",
        id: "nav-events-list-item",
        name: "Events",
    },
    {
        href: "/news",
        id: "nav-news-item",
        name: "News",
    },

    {
        href: "/directory",
        id: "nav-community-item",
        name: "Community",
    },
    {
        href: "/onedatateam",
        id: "nav-odt-item",
        name: "One Data Team",
    }
    ]

    if (isAdmin) {
        navbarItems.push({
            href: "/admin",
            id: "nav-admin-item",
            name: "Admin",
        })
    }
    return navbarItems
}

const getNavbarItems = (isAdmin: boolean, userId: string, userName: string, doLogout): NavBarLinks[] => {
    const navbarItems = getMainNavbarItems(isAdmin).concat(getNavbarIconItems(userId, doLogout))

    if ((userName?.length ?? 0) > 0) {
        navbarItems.push({
            href: "/#",
            id: "avatar",
            name: <NJAvatar href="" initials={getFirstLetters(userName)} label={userName} size="md" />
        })
    }
    return navbarItems
}

const useAgoraNavBar = (isAdmin, userId, userName, doLogout) => {
    const [navBarItems, setNavBarItems] = useState<NavBarLinks[]>([])
    const [activeItem, setActiveItem] = useState(firstItem)

    useEffect(() => setNavBarItems(getNavbarItems(isAdmin, userId, userName, doLogout)), [userName])
    useEffect(() => {
        const menuPath = `/${(window.location.pathname.split('/'))?.[1] ?? ''}`
        const foundItem = navBarItems.find((value) => {
            return menuPath !== '/' && value.href.startsWith(menuPath)
        })
        setActiveItem(foundItem ? foundItem?.id : firstItem)

    }, [window.location.pathname, navBarItems.length])

    return { activeItem, navBarItems, getFirstLetters }
}

export { useAgoraNavBar }
