export enum ContentTypes {
  POST = 'post',
  PROJECT = 'project',
  EVENT = 'event',
  EVENTADMIN = 'eventAdmin',
  INITIATIVE = 'initiative',
  PROFILE = 'profile',
  BE = 'be',
  BU = 'bu',
}
